/* 
  these are the options for splide image slider.  
  more info here>> https://splidejs.com/category/users-guide/
*/

export const splideOptions = {
  autoplay: true,
  interval: 5000,
  type: 'loop',
  cover: true,
  height: '90vh',
  pagination: true,
  breakpoints: {
    450: {
      height: '350px',
    },
    640: {
      height: '450px',
    },
    800: {
      height: '550px',
    },
    1200: {
      height: '650px',
    },
  },
};
