// scrolling functionality for Safari and Apple devices.

/*
  unfortunately because apple browsers do not support native smooth-scroll behavior.
  I have to implement a polyfill and function that calls link elements with scoll-into-view.
  
  ***don't remove until apple browser accepts smooth-scroll or unless you don't need to target
     apple devices. ***
*/

export default function handleScrollEvents() {
  const scrollToElement = (link, el) => {
    const links = document.querySelectorAll(link);
    const element = document.querySelector(el);

    links.forEach(link => {
      link.addEventListener('click', () => {
        element.scrollIntoView({ behavior: 'smooth' });
      });
    });
  };

  scrollToElement('.home-link', '#header');
  scrollToElement('.mission-link', '#mission');
  scrollToElement('.about-link', '#about');
  scrollToElement('.video-link', '#video');
  scrollToElement('.scholar-link', '#scholarship');
  scrollToElement('.recipient-link', '#recipients');
  scrollToElement('.contact-link', '#contact');
}
