function addClass(element, className) {
  element.classList.add(className);
}

function removeClass(element, className) {
  element.classList.remove(className);
}

export default function activateMobileMenu() {
  const sideMenu = document.querySelector('.side-menu');
  const mobileMenu = document.querySelector('.mobile-menu-container');
  const menuTarget = document.querySelector('.mobile-button-target');

  function openMobileMenu() {
    if (!sideMenu.classList.contains('active')) {
      addClass(sideMenu, 'active');
      mobileMenu.style = 'background:transparent; box-shadow:none;';
    } else {
      removeClass(sideMenu, 'active');
      mobileMenu.style = 'background:white; box-shadow:set;';
    }
  }

  function updateMenuStyles() {
    const mobileMenuPositionY =
      mobileMenu.getBoundingClientRect().y + document.documentElement.scrollTop;

    if (!sideMenu.classList.contains('active')) {
      if (window.innerWidth <= 768 && mobileMenuPositionY >= 83) {
        addClass(mobileMenu, 'active-style');
        mobileMenu.style = 'background:white; box-shadow:set;';
      } else {
        removeClass(mobileMenu, 'active-style');
      }
    }
  }

  // if browser window is resized back to desktop or tablet view
  // remove mobile menu and styles
  function closeMobileMenu() {
    if (window.innerWidth > 768) {
      removeClass(sideMenu, 'active');
      removeClass(mobileMenu, 'active-style');
    } else {
      updateMenuStyles();
    }
  }

  // if click occurs outside of the side menu close the
  // menu and remove styles
  const clickOutside = event => {
    let targetElement = event.target;
    do {
      if (targetElement === sideMenu || targetElement === mobileMenu) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);

    if (sideMenu.classList.contains('active')) {
      removeClass(sideMenu, 'active');
      mobileMenu.style = 'background:white; box-shadow:set;';
    }
  };

  updateMenuStyles();

  document.body.addEventListener('click', clickOutside);
  menuTarget.addEventListener('click', openMobileMenu);
  document.addEventListener('scroll', updateMenuStyles);
  window.addEventListener('resize', closeMobileMenu);
}
