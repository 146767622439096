/* 
ACCORDION COMPONENT

accordion component takes a boolean as second parameter.  If true the accordion will only
open one panel at a time.  If set to false you can open multiple panels at once.
*/

export default function toggleAccordion(element, option) {
  document.addEventListener('click', function (event) {
    if (!event.target.matches(`${element} .accordion__panel`)) {
      return;
    } else {
      if (!event.target.parentElement.classList.contains('active')) {
        if (option === true) {
          const elementList = document.querySelectorAll(
            `${element} .accordion__container`
          );
          Array.prototype.forEach.call(elementList, function (e) {
            e.classList.remove('active');
          });
        }
        event.target.parentElement.classList.add('active');
      } else {
        event.target.parentElement.classList.remove('active');
      }
    }
  });
}
