import 'normalize.css';
import './scss/main';

import activateMobileMenu from './js/mobile-menu/menu';
import toggleAccordion from './js/accordion';
import Splide from '@splidejs/splide';
import { splideOptions } from './js/slider/sliderOptions';
import smoothscroll from 'smoothscroll-polyfill';
import handleScrollEvents from './js/scroll-func/scrollFunc';

window.addEventListener('load', () => {
  // these handle smooth-scrolling for apple-devices and browsers
  handleScrollEvents();
  smoothscroll.polyfill();

  // initialize the image slider with default options
  const splide = new Splide('#splide', splideOptions).mount();

  splide.on('autoplay:pause', function () {
    splide.refresh();
  });
  // initialize accordion component
  toggleAccordion('.accordion', true);
  // mobile nav menu functionality
  activateMobileMenu();
});
